import React, { useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { Link } from "gatsby";

import { connector, ContainerProps } from "./containers/Forgot.container";
import * as Buttons from "../components/Buttons";
import { ArrowLeft } from "../components/Icons/ArrowLeft";

type ForgotProps = {
  isFetching: boolean;
  onSubmit: (params: { email: string }) => any;
};

const ForgotWrapper: React.FC<ForgotProps> = ({ onSubmit, isFetching }) => {
  const [email, setEmail] = useState("");

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit({
      email,
    });
  };

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="fixed w-full top-0">
        <div className="container">
          <Link to="/">
            <button className="px-4 py-4">
              <ArrowLeft className="text-gray-600" />
            </button>
          </Link>
        </div>
      </div>
      <div className="max-w-md w-full">
        <div>
          <h1 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
            Mot de passe oublié ?
          </h1>
          <p className="mt-4 text-center text-sm leading-5 text-gray-600">
            Vous n'avez finalement pas oublié votre mot de passe ?{" "}
            <Link
              to="/se-connecter/"
              className="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150"
            >
              Cliquez ici pour revenir en arrière.
            </Link>
          </p>
        </div>

        <form className="mt-10" onSubmit={submit}>
          <div className="rounded-md shadow-sm">
            <div>
              <input
                aria-label="Adresse email"
                name="email"
                type="email"
                required
                className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
                placeholder="Adresse email"
                value={email}
                onChange={(evt) => setEmail(evt.target.value)}
              />
            </div>
          </div>

          <div className="mt-6">
            <Buttons.Signin label={"Envoyer"} isFetching={isFetching} />
          </div>
        </form>
      </div>
    </div>
  );
};

export const ForgotContainer: React.FC<ContainerProps & RouteComponentProps> = (props) => (
  <ForgotWrapper {...props} />
);

export const Forgot = connector(ForgotContainer);
