import React from "react"
import { PageProps } from "gatsby"

import { Forgot } from "../routes/Forgot"
import { Seo } from "../components/Seo/Seo"

export default ({ location }: PageProps) => (
  <>
    <Seo
      index={false}
      title="Mot de passe oublié | Temple du Haïku"
      description="Retrouver votre mot de passe en nous envoyant une demande"
    />
    <Forgot />
  </>
)
