import { connect, ConnectedProps } from "react-redux";
import { actions } from "../../redux/actions";
import { RootState } from "../../redux/store";

const mapState = (state: RootState) => {
  return {
    isFetching: state.auth.fetching,
  };
};

const mapDispatch = (dispatch: any) => ({
  onSubmit: (params: { email: string }) =>
    dispatch(actions.auth.fetchForgot(params.email)),
});

export const connector = connect(mapState, mapDispatch);
export type ContainerProps = ConnectedProps<typeof connector>;
